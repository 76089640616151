exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-environmental-impact-in-the-modular-construction-industry-js": () => import("./../../../src/pages/blog/environmental-impact-in-the-modular-construction-industry.js" /* webpackChunkName: "component---src-pages-blog-environmental-impact-in-the-modular-construction-industry-js" */),
  "component---src-pages-blog-financing-challenges-in-the-modular-construction-industry-js": () => import("./../../../src/pages/blog/financing-challenges-in-the-modular-construction-industry.js" /* webpackChunkName: "component---src-pages-blog-financing-challenges-in-the-modular-construction-industry-js" */),
  "component---src-pages-blog-how-modular-solutions-are-leading-the-way-in-hotel-innovation-js": () => import("./../../../src/pages/blog/How-Modular-Solutions-are-Leading-the-Way-in-Hotel-Innovation.js" /* webpackChunkName: "component---src-pages-blog-how-modular-solutions-are-leading-the-way-in-hotel-innovation-js" */),
  "component---src-pages-blog-inspection-and-approval-processes-in-the-modular-construction-industry-js": () => import("./../../../src/pages/blog/inspection-and-approval-processes-in-the-modular-construction-industry.js" /* webpackChunkName: "component---src-pages-blog-inspection-and-approval-processes-in-the-modular-construction-industry-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-managing-quality-control-in-the-modular-construction-industry-js": () => import("./../../../src/pages/blog/managing-quality-control-in-the-modular-construction-industry.js" /* webpackChunkName: "component---src-pages-blog-managing-quality-control-in-the-modular-construction-industry-js" */),
  "component---src-pages-blog-modular-development-holistic-sustainability-js": () => import("./../../../src/pages/blog/modular-Development-Holistic-Sustainability.js" /* webpackChunkName: "component---src-pages-blog-modular-development-holistic-sustainability-js" */),
  "component---src-pages-blog-most-common-business-models-in-the-modular-construction-industry-js": () => import("./../../../src/pages/blog/most-common-business-models-in-the-modular-construction-industry.js" /* webpackChunkName: "component---src-pages-blog-most-common-business-models-in-the-modular-construction-industry-js" */),
  "component---src-pages-blog-revolutionizing-hospitality-unlocking-speed-and-efficiency-with-modular-solutions-js": () => import("./../../../src/pages/blog/Revolutionizing Hospitality -Unlocking-speed-and-efficiency-with-modular-solutions.js" /* webpackChunkName: "component---src-pages-blog-revolutionizing-hospitality-unlocking-speed-and-efficiency-with-modular-solutions-js" */),
  "component---src-pages-blog-transportation-challenges-in-the-modular-construction-industry-js": () => import("./../../../src/pages/blog/transportation-challenges in-the-modular-construction-industry.js" /* webpackChunkName: "component---src-pages-blog-transportation-challenges-in-the-modular-construction-industry-js" */),
  "component---src-pages-blog-why-real-estate-developers-are-embracing-modular-solutions-js": () => import("./../../../src/pages/blog/Why-Real-Estate-Developers-Are-Embracing-Modular-Solutions.js" /* webpackChunkName: "component---src-pages-blog-why-real-estate-developers-are-embracing-modular-solutions-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-our-journey-now-js": () => import("./../../../src/pages/join-our-journey-now.js" /* webpackChunkName: "component---src-pages-join-our-journey-now-js" */),
  "component---src-pages-mep-js": () => import("./../../../src/pages/mep.js" /* webpackChunkName: "component---src-pages-mep-js" */),
  "component---src-pages-modular-construction-js": () => import("./../../../src/pages/modular_construction.js" /* webpackChunkName: "component---src-pages-modular-construction-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-production-process-js": () => import("./../../../src/pages/production-process.js" /* webpackChunkName: "component---src-pages-production-process-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-projects-abudabi-resort-js": () => import("./../../../src/pages/projects/abudabi-resort.js" /* webpackChunkName: "component---src-pages-projects-abudabi-resort-js" */),
  "component---src-pages-projects-earth-hotel-js": () => import("./../../../src/pages/projects/earth-hotel.js" /* webpackChunkName: "component---src-pages-projects-earth-hotel-js" */),
  "component---src-pages-projects-kas-2000-key-hotel-js": () => import("./../../../src/pages/projects/kas-2000-key-hotel.js" /* webpackChunkName: "component---src-pages-projects-kas-2000-key-hotel-js" */),
  "component---src-pages-projects-neom-constructions-js": () => import("./../../../src/pages/projects/neom-constructions.js" /* webpackChunkName: "component---src-pages-projects-neom-constructions-js" */),
  "component---src-pages-projects-neom-project-js": () => import("./../../../src/pages/projects/neom-project.js" /* webpackChunkName: "component---src-pages-projects-neom-project-js" */),
  "component---src-pages-projects-tennis-acedemy-js": () => import("./../../../src/pages/projects/tennis-acedemy.js" /* webpackChunkName: "component---src-pages-projects-tennis-acedemy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-single-careers-js": () => import("./../../../src/pages/single_careers.js" /* webpackChunkName: "component---src-pages-single-careers-js" */),
  "component---src-pages-structure-js": () => import("./../../../src/pages/structure.js" /* webpackChunkName: "component---src-pages-structure-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-why-modular-js": () => import("./../../../src/pages/why-modular.js" /* webpackChunkName: "component---src-pages-why-modular-js" */),
  "component---src-template-inner-news-js": () => import("./../../../src/template/inner-news.js" /* webpackChunkName: "component---src-template-inner-news-js" */)
}

